<template>
  <div ref="copUserPermsRef">
    <a-modal
        :getContainer="()=>$refs.copUserPermsRef"
        wrapClassName="user-drawer"
        width="62.5vw"
        title="选择用户"
        :visible="visible"
        :cancelText="'取消'"
        :okText="'确定'"
        @cancel="handleCancel"
        @ok="handleSave"
        centered
    >
      <div class="padding">
        <div class="title">已选角色及权限</div>
        <a-table
            :columns="selectUserColumns"
            :data-source="selectdUserList"
            rowKey="id"
            :scroll="{
                y: 500,
                scrollToFirstRowOnChange: true,
              }"
            :pagination="false"
        >
        </a-table>
      </div>
      <div class="padding">
        <div class="title">选择需要复制的人员</div>
        <a-select
            style="width: 100%"
            v-model:value="roleName"
            placeholder="请选择"
            allowClear
            show-search
            mode="multiple"
            :maxTagCount="1"
            @select="handleRoleSelect"
        >
          <a-select-option
              v-for="item in roleOps"
              :key="item.id"
              :value="
              item.fullName +
              ' ' +
              item.email
              "
          >
            {{ item.fullName + " " + item.email }}
          </a-select-option>
        </a-select>
      </div>
      <div class="padding">
        <div class="title">选中人员预览</div>
        <a-table
            :columns="columns"
            :data-source="userList"
            rowKey="id"
            :scroll="{
              y: 500,
              scrollToFirstRowOnChange: true,
            }"
            :pagination="false"
        >
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>

import {computed, defineComponent, ref, watch} from "vue";
import { onMounted } from "@vue/runtime-core";
import { useGlobalPropertyHook } from "@/hooks/common";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "AddPersonDrawer",
  components: {},
  emits:['cancel','save'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectUserList: {
      type: Array,
    },
    selectdRoleId: {
      type: Array,
    }
  },
  setup(props,{ emit }) {
    const { $api } = useGlobalPropertyHook();
    const selectdUserList = computed(()=>props.selectUserList)
    const selectUserColumns = [
      {
        title: "角色",
        dataIndex: "roleName",
        key: "roleName",
        width: 100,
        ellipsis: true,
        align: "left"
      },
      {
        title: "Offering",
        dataIndex: "offering",
        key: "offering",
        width: 180,
        ellipsis: true,
        align: "left"
      },
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        width: 180,
        ellipsis: true,
        align: "left"
      },
    ]

    const columns = [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 150,
        ellipsis: true,
        align: "left"
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        width: 150,
        ellipsis: true,
        align: "left"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 150,
        ellipsis: true,
        align: "left"
      },
    ];
    const userList = computed(() => {
      if (roleOps.value && roleOps.value.length) {
        return roleOps.value.filter(item => {
          const userStr = item.fullName + " " + item.email;
          return roleName.value.indexOf(userStr) !== -1
        })
      }
      return [];
    });

    const roleName = ref([])
    const roleOps = ref([])
    const getRoleList = () => {
      $api.findAllStaffs().then((res) => {
        roleOps.value = res.data;
      });
    }
    const selectUser = ref([])
    const handleRoleSelect = (selectVal) => {
      selectUser.value = roleOps.value.find(item => {
        const userStr = item.fullName + " " + item.email;
        return selectVal === userStr;
      })
      const params = {
        roleIdList: props.selectdRoleId,
        userId: selectUser.value.id
      }
      $api.checkCopyRoleMutex(params).then((res) => {
        if(res.data === 0){
          message.error(res.msg);
          const index = roleName.value.indexOf(selectVal);
          roleName.value.splice(index, 1);
        }
      });
    }

    const handleCancel = () => {
      roleName.value = []
      emit("cancel");
    };
    const handleSave = () => {
      const params = {
        roleDataPermsCommands:selectdUserList.value,
        userIds:userList.value.map(item => item.id)
      }
      $api.copyRolePerms(params).then((res) => {
        if(res.code === 200) {
          message.success('保存成功');
          sessionStorage.setItem('roleName', roleName.value)
          sessionStorage.setItem('selectdUserList', selectdUserList.value.map(item => item.roleName))
          roleName.value = []
        } else {
          roleName.value = []
        }
      });
      emit("cancel")
    };

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            getRoleList()
             if(sessionStorage.getItem("roleName") && sessionStorage.getItem("selectdUserList")) {
               if(selectdUserList.value.length === sessionStorage.getItem("selectdUserList").split(",").length) {
                 let a = true
                 for(var i=0;i<sessionStorage.getItem("selectdUserList").split(",").length;i++){
                   if(selectdUserList.value.map(item => item.roleName).includes(sessionStorage.getItem("selectdUserList").split(",")[i])){
                     a = false;
                   } else {
                     a = true;
                   }
                 }
                 if(a === false) {
                   roleName.value = sessionStorage.getItem("roleName")
                 }
               }
             }
          }
        }
    )

    onMounted(() => {

    });

    return {
      selectdUserList,
      selectUserColumns,
      columns,
      userList,
      roleName,
      roleOps,
      handleCancel,
      handleSave,
      getRoleList,
      handleRoleSelect,
      selectUser,
    };
  }
});
</script>

<style lang="less" scoped>
.header{
    display: flex;
    justify-content: space-between;
}
.title{
  font-size: 18px;
  font-weight: 800;
  line-height: 36px;
  margin-bottom: 10px;
}


.add-task-select {
  width: 100%;
  margin-top: 8px;
}

</style>

<style lang="less" scoped>
@import "~@/style/aModal.less";
.add-task-select-option {
  .option {
    display: flex;

    .name {
      width: 50%;
    }

    .onboardDate {
      width: 25%;
    }

    .terminationDate {
      width: 25%;
    }
  }
}
.padding {
  margin: 10px 0;
  padding: 0 24px;
}
</style>

