<template>
  <section class="org-list">
    <a-table
        ref="userManagementListRef"
        :columns="columns"
        :data-source="userManagementList"
        rowKey="id"
        :scroll="{
        y: tableHeight,
        x: 1200,
        scrollToFirstRowOnChange: true,
      }"
        :pagination="pagination"
        :customRow="customRowFunc"
    >
    </a-table>
    <EditUserPermsDrawer
        v-model:visible="editUserPermsVisible"
        @close-modal="closeEditDrawer"
        @save-modal="saveEditDrawer"
        :edit-perms-date="activeRow"
    />
  </section>
</template>

<script>
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import {useTableHeight} from "@/utils/common";
import {useGlobalPropertyHook} from "@/hooks/common";
import EditUserPermsDrawer from "@/views/home/ConfigManagement/UserManagement/components/EditUserPermsDrawer";

export default {
  name: "UserManagementList",
  components: {EditUserPermsDrawer},
  setup(){
    const userManagementListRef = ref()
    const userManagementList = ref();
    const { $api } = useGlobalPropertyHook();
    const columns = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 150,
      },
      {
        title: "角色",
        dataIndex: "roleName",
        key: "roleName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        ellipsis: true,
        width: 150,
      },
    ];
    const editUserPermsVisible = ref(false)
    const activeRow = ref({
      fullName: "",
      ggid: '',
    });
    const customRowFunc = (record) => {
      return {
        onClick: () => {
          activeRow.value.fullName = record.fullName
          activeRow.value.ggid = record.ggid
          sessionStorage.removeItem('roleName')
          sessionStorage.removeItem('selectdUserList')
          editUserPermsVisible.value = true
        },
      };
    };

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    let searchCondition = {};
    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.getUserRoleInfos(params).then((res) => {
        pagination.total = res.data.total;
        userManagementList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const closeEditDrawer = () => {
      editUserPermsVisible.value = false
    }
    const saveEditDrawer = () => {
      editUserPermsVisible.value = false
      doSearch(searchCondition)
    }

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
      searchClick()
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return{
      userManagementListRef,
      columns,
      pagination,
      userManagementList,
      tableHeight,
      doSearch,
      searchClick,
      getSize,
      customRowFunc,
      editUserPermsVisible,
      closeEditDrawer,
      saveEditDrawer,
      activeRow,
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}
.org-list {
  display: flex;
  width: 100%;
  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
