<template>
  <a-drawer
      title="编辑权限"
      :visible="visible"
      @close="closeModal"
      width="64.6vw"
      wrapClassName="task-drawer"
  >
    <div class="info">
      <div class="info-cell">Name: {{editPermsDate.fullName}}</div>
      <div class="info-cell">GGID: {{editPermsDate.ggid}}</div>
    </div>
    <div class="perms">
      <span class="span">用户权限</span>
      <a-space style="float: right">
        <a-button type="primary" @click="handleCopy" :disabled="selectedUserList.length === 0 || isEdit === false" v-auth="['system:userManagement:copy']">复制</a-button>
        <a-button type="primary" @click="handleEdit" v-if="isEdit === true" v-auth="['system:userManagement:edit']">编辑</a-button>
        <a-button type="primary" @click="handleAdd" v-else v-auth="['system:userManagement:add']">Add</a-button>
      </a-space>
    </div>
    <div class="forms">
    <a-form class="permsForm" :model="userPermsDate" ref="formRef">
    <a-table
        :columns="userColumns"
        :data-source="userPermsDate"
        :pagination="false"
        row-key="roleId"
        :row-selection="{
          columnTitle: ' ',
          selectedRowKeys:selectedUserIdList,
          selectedRows: selectedUserList,
          onChange: userChange,
          getCheckboxProps: getCheckboxPropsFunc,
        }"
    >
      <template #relationRole="{ index, text, record}">
        <span v-if="isEdit === true">
          {{ text }}
        </span>
        <a-form-item :name="[index, 'roleName']"
                     :rules="[{
                             required: true,
                             message: '角色不能为空',
                             trigger: 'change',
                           },
                     ]" v-else>
        <a-select
            style="width: 100%"
            v-model:value="record.roleName"
            placeholder="请选择"
            show-search
            allowClear
            @change="relationRoleChange(record)"
            @click="roleOpsFilter"
        >
          <a-select-option
              v-for="(item, index) in roleOps"
              :key="index"
              :value="item.roleName"
          >
            {{ item.roleName }}
          </a-select-option>
        </a-select>
        </a-form-item>
      </template>
      <template #offering="{ index, text, record }">
        <span v-if="isEdit === true">
          {{ text }}
        </span>
        <a-form-item :name="[index, 'offering']"
                     :rules="[{
                             required: true,
                             message: 'offering不能为空',
                             trigger: 'change',
                             type: 'array',
                           },
                     ]" v-else>
          <SelectWithAll
              style="width: 100%"
              v-model:value="record.offering"
              placeholder="请选择"
              mode="multiple"
              showArrow
              allowClear
              @change="offeringChange(record)"
          >
            <a-select-option
                v-for="(item, index) in offeringOps"
                :key="index"
                :value="item"
            ><span :title="item">{{ item }}</span>
            </a-select-option>
          </SelectWithAll>
        </a-form-item>
      </template>
      <template #costCtr="{ index, text, record }">
        <span v-if="isEdit === true">
          {{ text }}
        </span>
        <a-form-item :name="[index, 'costCtr']"
                     :rules="[{
                             required: true,
                             message: 'costCtr不能为空',
                             trigger: 'change',
                             type: 'array',
                           },
                     ]" v-else>
          <SelectWithAll
              style="width: 100%"
              v-model:value="record.costCtr"
              placeholder="请选择"
              mode="multiple"
              showArrow
              allowClear
          >
            <a-select-option
                v-for="(item, index) in costCtrOps"
                :key="index"
                :value="item"
            ><span :title="item">{{ item }}</span>
            </a-select-option>
          </SelectWithAll>
        </a-form-item>
      </template>

      <template #operation="{index, record }">
        <a-space>
          <span class="operationLink underline" @click="handleDelete(index,record)" v-auth="['system:userManagement:delete']"
          >删除
          </span>
        </a-space>
      </template>
    </a-table>
    </a-form>
    </div>
    <div class="bottom-btn">
      <a-space style="float: right">
        <a-button type="primary" ghost @click="closeModal">取消</a-button>
        <a-button type="primary" @click="saveClick">保存</a-button>
      </a-space>
    </div>
    <CopyUserPermsDrawer
        v-model:visible="copyUserVisible"
        v-model:selectUserList="selectUserList"
        v-model:selectdRoleId="selectdRoleId"
        @cancel="closeCopyDrawer"
    />
  </a-drawer>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>

import {computed, ref, watch,} from "vue";
import CopyUserPermsDrawer from "@/views/home/ConfigManagement/UserManagement/components/CopyUserPermsDrawer";
import {useGlobalPropertyHook} from "@/hooks/common";
import {message} from "ant-design-vue";
import ModalBox from '@/components/ModalBox'
export default {
  name: "EditUserPermsDrawer",
  props: {
    visible: Boolean,
    editPermsDate: Object,
  },
  emits: ['close-modal', 'save-modal'],
  components: {CopyUserPermsDrawer,ModalBox},
  setup(props,{ emit }){
    const { $api } = useGlobalPropertyHook();
    const formRef = ref()
    const userPermsDate = ref([])
    const selectedUserList =ref([])
    const selectedUserIdList = ref([])
    const userColumns = computed(() => {
      let columns = [];
      columns = [
        {
          title: "角色",
          dataIndex: "roleName",
          key: "roleName",
          ellipsis: true,
          width: 100,
          slots: { customRender: "relationRole" }
        },
        {
          title: "Offering",
          dataIndex: "offering",
          key: "offering",
          ellipsis: true,
          width: 320,
          slots: { customRender: "offering" },
          customCell:() => {
            return {
              style: {
                wordWrap:'break-word',
                wordBreak:'break-all',
                whiteSpace:'normal',
                minHeight:'50px',
                width: '50px',
              }
            }
          }
        },
        {
          title: "Cost Ctr",
          dataIndex: "costCtr",
          key: "costCtr",
          ellipsis: true,
          width: 320,
          slots: { customRender: "costCtr" },
          customCell:() => {
            return {
              style: {
                wordWrap:'break-word',
                wordBreak:'break-all',
                whiteSpace:'normal',
                minHeight:'50px',
                width: '50px',
              }
            }
          }
        },
      ]
      if(isEdit.value === false) {
        columns.push({
          title: "操作",
          dataIndex: "operation",
          ellipsis: true,
          width: 80,
          slots: { customRender: "operation" },
        })
      }
      return [...columns]
    })

    const roleOps = ref([])
    const roleAllOps = ref([])
    const offeringOps  = ref([])
    const costCtrOps = ref([])
    const costCtrOpsCopy = ref([])

    const getListDate = () => {
      const params = {
        ggid:props.editPermsDate.ggid
      }
      $api.getUserPerms(params).then((res) => {
        userPermsDate.value = res.data
      })
    }

    const costCtrAndOfferingItems = () => {
      $api.getCostCtrAndOfferingItems().then((res) => {
        const { offering,costCtr}  = res.data;
        offeringOps.value = offering;
        costCtrOps.value = costCtr
        costCtrOpsCopy.value = costCtr
      })
    };
    const orgAllList = ref([])
    const getCostCtrAndOfferingRelations = () => {
      $api.costCtrAndOfferingRelations().then((res) => {
        orgAllList.value = res.data
      })
    }
    const offeringChange = (record) => {
      const temp = {}
      record.offering.forEach(item => temp[item] = true)
      costCtrOps.value = orgAllList.value.filter(item => temp[item.org_four_one_one]).map(item => item.cost_ctr)
      if(record.costCtr){
        record.costCtr = record.costCtr.filter(item => {
          if(costCtrOps.value.includes(item)) {
            return item
          }
        })
      }
      if(record.offering.length === 0){
        record.costCtr = []
        costCtrOps.value = costCtrOpsCopy.value
      }
    }

    const getRoleIdList = () => {
      $api.getRoleList({status:'user'}).then((res) => {
        roleOps.value = res.data.filter(item => item.roleName !== 'PM' && item.roleName !== 'LM' && item.roleName !== '顾问')
        roleAllOps.value = res.data.filter(item => item.roleName !== 'PM' && item.roleName !== 'LM' && item.roleName !== '顾问')
      })
    }

    const userChange = (selectedRowKeys,selectedRows) => {
      selectedUserIdList.value = selectedRowKeys
      selectedUserList.value = selectedRows
    }
    const getCheckboxPropsFunc = (record) => {
      if(isEdit.value === true) {
        return {
          disabled: record.roleName === 'COO'
        };
      }
    };
    const relationRoleChange = (record) => {
      if(record.roleName) {
        const params = {
          existRoleList: userPermsDate.value.map(item => item.roleName).filter(item => item !== record.roleName),
          newRole: record.roleName
        }
        $api.checkEditRoleMutex(params).then((res) => {
          if (res.data === 0) {
            record.roleName = ''
            message.error(res.msg)
            roleOps.value = roleAllOps.value.filter(item => {
              if (!userPermsDate.value.map(i => i.roleName).includes(item.roleName)) {
                return item
              }
            })
          }
        })
      }
    }
    const roleOpsFilter = () => {
      roleOps.value = roleAllOps.value.filter(item => {
        if(!userPermsDate.value.map(i => i.roleName).includes(item.roleName)){
          return item
        }
      })
    }

    const isEdit = ref(true)
    const closeModal = () => {
      isEdit.value = true
      selectedUserIdList.value = []
      selectedUserList.value = []
      sessionStorage.removeItem('roleName')
      sessionStorage.removeItem('selectdUserList')
      emit("close-modal");
    };

    const saveClick = () => {
      Promise.all([formRef.value.validate()]).then(() => {
        if(isEdit.value === false) {
          isEdit.value = true
          let roleDataPermsCommand = userPermsDate.value.map(item => {
            return {
              costCtr: item.costCtr.join(' , '),
              relationRole: item.roleName,
            }
          })
          const params = {
            ggid: props.editPermsDate.ggid,
            roleDataPermsCommands: roleDataPermsCommand,
          }
          $api.editUserPerms(params).then(() => {
            message.success("保存成功");
            emit('save-modal')
          })
        }
        selectedUserIdList.value = []
        selectedUserList.value = []
        emit("close-modal");
      });
    }
    const copyUserVisible = ref(false)
    const selectUserList = ref([])
    const selectdRoleId = ref([])
    const handleCopy = () => {
      selectUserList.value = selectedUserList.value
      let roleId = roleAllOps.value.filter(item => {
        if(selectedUserList.value.map(i => i.roleName).includes(item.roleName)){
          return item
        }
      })
      selectdRoleId.value = roleId.map(item => item.roleId)
      copyUserVisible.value = true
    }
    const closeCopyDrawer = () => {
      copyUserVisible.value = false
    }
    const handleEdit = () => {
      isEdit.value = false
      for (let i = 0; i <userPermsDate.value.length ; i++) {
        let offeringData=userPermsDate.value[i].offering;
        let costCtrData=userPermsDate.value[i].costCtr;
        userPermsDate.value[i].offering=offeringData.split(" , ");
        userPermsDate.value[i].costCtr=costCtrData.split(" , ");
      }
      if(userPermsDate.value.length === 0){
        handleAdd()
      }
    }
    const handleAdd = () => {
      let newRow = {
        roleName: '',
        offering:[],
        costCtr:[],
      }
      userPermsDate.value.push(newRow)
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {index,record} = modalData.value
      const params = {
        ggid:props.editPermsDate.ggid,
        role:record.roleName
      }
      $api.deleteUserPerms(params).then(() => {
        userPermsDate.value.splice(index,1)
      })
    }
    const handleDelete = (index,record) => {
      content.value = '确定删除本条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = {index,record}
      modelValue.value = true
      /*Modal.confirm({
        title: '确定删除本条数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        onOk() {
          const params = {
            ggid:props.editPermsDate.ggid,
            role:record.roleName
          }
          $api.deleteUserPerms(params).then(() => {
            userPermsDate.value.splice(index,1)
          })
        },
        cancelText: '取消',
      });*/
    }

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            getListDate();
            costCtrAndOfferingItems();
            getRoleIdList();
            getCostCtrAndOfferingRelations();
          }
        }
    )
    return{
      formRef,
      userPermsDate,
      userColumns,
      isEdit,
      roleOps,
      roleAllOps,
      offeringOps,
      costCtrOps,
      costCtrOpsCopy,
      selectedUserIdList,
      selectedUserList,
      getListDate,
      userChange,
      getCheckboxPropsFunc,
      closeModal,
      saveClick,
      copyUserVisible,
      handleCopy,
      handleEdit,
      handleAdd,
      handleDelete,
      selectUserList,
      selectdRoleId,
      closeCopyDrawer,
      costCtrAndOfferingItems,
      getRoleIdList,
      relationRoleChange,
      roleOpsFilter,
      getCostCtrAndOfferingRelations,
      orgAllList,
      offeringChange,
      modelValue,
      content,
      showIconCom,
      handleOk,
    }
  }
}
</script>

<style lang="less" scoped>
.task-drawer{
  .info{
    margin: 12px 0 12px 0;
    padding: 24px;
    width: 100%;
    height: 108px;
    display: flex;
    flex-wrap: wrap;
    .info-cell {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      color: #2d3748;
      margin-bottom: 16px;
    }
  }
  .perms{
    margin: 0 24px 24px;
    .span{
      font-size: 18px;
      font-weight: 800;
      line-height: 36px;
    }
  }
  .permsForm {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
</style>
