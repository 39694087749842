<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
          @doReset="resetForm"
          @doSearch="doSearch"
          ref="filterLayoutRef"
          @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible"
          :isShowMoreFlag="isShowMoreList">
        <template #customform>
<!--          <a-form
              ref="formRef"
              class="form rms-form box"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="Org5.1" name="orgFiveOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFiveOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange('orgFiveOne')"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.orgFiveOneOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgThreeTwoList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange('orgThreeTwo')"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.orgThreeTwoOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFourOneOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange('orgFourOneOne')"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.orgFourOneOneOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Cost Ctr" name="costCtrList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.costCtrList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.costCtrOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="人员" name="staffCondition" class="rms-form-item">
              <a-input
                  v-model:value="formState.staffCondition"
                  placeholder="Name/GGID"
                  allowClear
                  autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="角色" name="roleIdList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.roleIdList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
              >
                <a-select-option
                    v-for="(item, index) in roleIdOps"
                    :key="index"
                    :value="item.roleName"
                ><span :title="item">{{ item.roleName }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </a-form>-->
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=4">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        placeholder="Name/GGID"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          placeholder="Name/GGID"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button type="primary" ghost @click="exportClick" v-auth="['system:userManagement:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList" :sortFlag="true"></FormModalBox>
  </section>
</template>

<script>
import {onActivated, onMounted, reactive, ref} from "vue";
import {cloneDeep, debounce} from "lodash";
import {getValueAndOps} from "@/utils/orgSelectData";
import {useGlobalPropertyHook} from "@/hooks/common";
import { ExportOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import {dictionaryOpt} from '@/utils/common'
export default {
  name: "Filter",
  components: {Tooltip, ExportOutlined, MoreFormList, FormModalBox },
  emits: ["search", "export-click", "refresh-table"],

  setup(props, ctx){
    const formRef = ref();
    const { $api } = useGlobalPropertyHook();
    const orgFiveOneOps = ref([])
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const costCtrOps = ref([]);
    const roleIdOps = ref([])
    let initData = {
      staffCondition: "",
      orgFiveOneList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      costCtrList: [],
      roleIdList: [],
    }
    const formState = reactive({...initData});
    let currentState = {};
    const updateParams =() => {
      currentState = cloneDeep(formState)
    };

    const orgOpsObj = reactive({
      orgTwoOps: [],
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: [],
      costCtrOps: [],
    });
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgFiveOneList, orgThreeTwoList ,costCtrList}  = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
        orgOpsObj.costCtrOps = dictionaryOpt(costCtrList);
        costCtrOps.value = dictionaryOpt(costCtrList)
      })
    };
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList
      }
      const {orgsValue, orgOpsList} = getValueAndOps(orgAllList.value,formOrgData,orgOpsObj,type)
      Object.assign(formState,orgsValue)
      Object.assign(orgOpsObj,orgOpsList)
      if(type === 'orgFourOneOne') {
        formState.costCtrList = []
        const temp = {}
        formState.orgFourOneOneList.forEach(item => temp[item] = true)
        orgOpsObj.costCtrOps = dictionaryOpt(orgAllList.value.filter(item => temp[item.orgFourOneOne]).map(item => item.costCtr))
        if(formState.orgFourOneOneList.length === 0){
          orgOpsObj.costCtrOps = costCtrOps.value
        }
      }
    };
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }
    const getRoleIdList = () => {
      $api.getRoleList({status:'enable'}).then((res) => {
        roleIdOps.value = res.data.filter(item => item.roleName !== 'PM' && item.roleName !== 'LM' && item.roleName !== '顾问')
        roleIdOps.value = roleIdOps.value.map((item,index)=>{
          return {
            ...item,
            label: item.roleName,
            value: item.roleName,
            key: index,
          }
        })
      })
    }

    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams()
      getOrgList()
      ctx.emit("search", currentState);
    };
    const doSearch = async () => {
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleName)){
          return item.roleId
        }
      }).map(item => item.roleId)
      updateParams()
      await ctx.emit("search", currentState);
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleId)){
          return item.roleName
        }
      }).map(item => item.roleName)
    };
    const exportClick = () => {
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleName)){
          return item.roleId
        }
      }).map(item => item.roleId)
      ctx.emit("export-click", currentState);
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleId)){
          return item.roleName
        }
      }).map(item => item.roleName)
    };
    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'systemSettings',
        secondMenu: 'userManagement',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'costCtrList':
          return orgOpsObj.costCtrOps
        case 'roleIdList':
          return roleIdOps.value
        default :
          ''  // 如果没
      }
    }
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const getSelectChange = debounce((data)=> {
      console.log(data)
      switch (data) {
        case 'orgThreeTwoList':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOneList':
          return orgThreeTwoChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }

    onMounted(() => {
      getOrgList();
      getOrgRelatedList();
      getRoleIdList();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formRef,
      formState,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgFiveOneOps,
      costCtrOps,
      roleIdOps,
      orgOpsObj,
      updateParams,
      getOrgList,
      orgThreeTwoChange,
      getOrgRelatedList,
      getRoleIdList,
      resetForm,
      doSearch,
      exportClick,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  }
}
</script>

<style scoped lang="less">
.top-filter {
  width: 100%;
  .box{
    height: 160px;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
