<template>
  <section class="app-container">
    <Filter
        @search="handleSearch"
        @export-click="exportClick"
        @refresh-table="handleResetTable"
    />
    <UserManagementList ref="userManagementListRef"></UserManagementList>
  </section>
</template>

<script>
import Filter from "@/views/home/ConfigManagement/UserManagement/components/Filter";
import UserManagementList from "@/views/home/ConfigManagement/UserManagement/components/UserManagementList";
import {ref} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {downloadFile} from "@/utils/common";

export default {
  name: "index",
  components: {UserManagementList, Filter},

  setup(){
    const userManagementListRef = ref()
    const { $api } = useGlobalPropertyHook();

    const handleSearch = (searchParams) => {
      userManagementListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      userManagementListRef.value.doSearch();
    };
    const exportClick = (searchParams) => {
      $api.userRoleExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    return{
      userManagementListRef,
      handleSearch,
      handleResetTable,
      exportClick,
    }
  }
}
</script>